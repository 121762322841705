const multipliers = [1, 60, 60 * 60]

export function parseDuration(value: string) {
  const segments = value.split(':').reverse()

  if (segments.length > 3) {
    throw new Error('Invalid duration')
  }

  return segments.reduce((result, segment, index) => {
    return result + parseInt(segment) * multipliers[index]
  }, 0)
}
