import * as styles from './PodcastLayout.module.scss'

import React, { ReactNode } from 'react'
import { compact } from 'lodash'
import { Link } from 'gatsby'

import podcastArt from './lucky-paper-radio-artwork.jpg'

import { PodcastPlayer } from './PodcastPlayer'
import { podcastVendors } from './podcastVendors'
import { usePlayerState } from './PlayerContext'
import { trailerAudioPath } from './podcastConfig'

interface Props {
  children: ReactNode
  episode?: Exclude<
    Queries.PodcastTemplateQuery['podcast'],
    null
  >['frontmatter']
  previousEpisode?: {
    title: string
    episodeSlug: string | null
  } | null
  nextEpisode?: {
    title: string
    episodeSlug: string | null
  } | null
}

const PodcastLayout: React.FC<Props> = (props) => {
  const { children, episode, previousEpisode, nextEpisode } = props

  const { playing, setRequestedAction } = usePlayerState()

  return (
    <>
      <section>
        {(previousEpisode != null || nextEpisode != null) && (
          <div className={styles.episodeNavigation}>
            {previousEpisode != null ? (
              <Link
                to={`/podcast/${previousEpisode.episodeSlug}/`}
                className={styles.episodeNavigationButton}
              >
                <div>&larr;</div>
                <div>
                  <div>Previous Episode</div>
                  <div className={styles.episodeNavigationButtonTitle}>
                    {previousEpisode.title}
                  </div>
                </div>
              </Link>
            ) : (
              <div className={styles.episodeNavigationPlaceholder}></div>
            )}

            <Link className={styles.episodeNavigationButton} to="/podcast">
              All Episodes
            </Link>

            {nextEpisode != null ? (
              <Link
                to={`/podcast/${nextEpisode.episodeSlug}/`}
                className={styles.episodeNavigationButton}
              >
                <div>
                  <div>Next Episode</div>
                  <div className={styles.episodeNavigationButtonTitle}>
                    {nextEpisode.title}
                  </div>
                </div>
                <div>&rarr;</div>
              </Link>
            ) : (
              <div className={styles.episodeNavigationPlaceholder}></div>
            )}
          </div>
        )}

        <div className={styles.main}>
          <div className={styles.masthead}>
            <button
              className={styles.mastheadButton}
              onClick={() => {
                setRequestedAction(playing ? 'pause' : 'play')
              }}
            >
              <img
                src={podcastArt}
                className={styles.mastheadImage}
                alt="Lucky Paper Radio Podcast Artwork"
              />
            </button>
            <div className={styles.mastheadInfo}>
              {episode ? (
                <>
                  <div className={styles.subhead}>
                    {episode.episodeNumber
                      ? `Episode ${episode.episodeNumber}`
                      : 'Bonus Episode'}{' '}
                    — {episode.formattedDate}
                  </div>
                  <div className={styles.title}>{episode.title}</div>
                </>
              ) : (
                <>
                  <h1 className={styles.title}>Lucky Paper Radio</h1>
                  <p className={styles.excerpt}>
                    Lucky Paper Radio is a podcast about Magic: the Gathering
                    hosted by lifelong friends Andy Mangold and Anthony Mattox.
                    Focused primarily on sandbox formats like Cube, which are
                    free of banlists, rotation, and net decking, the show is
                    about the best parts of Magic and how to get the most out of
                    the game.
                  </p>

                  <button
                    className={styles.trailerButton}
                    onClick={() => {
                      setRequestedAction(playing ? 'pause' : 'play')
                    }}
                  >
                    {playing ? 'Pause' : 'Play Trailer'}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className={styles.vendorLinks}>
            <div className={styles.vendorsLabel}>Listen &amp; Subscribe:</div>
            {podcastVendors.map((vendor, index) => (
              <a className={styles.vendorLink} href={vendor.link} key={index}>
                <img
                  className={styles.vendorIcon}
                  src={`/podcast-vendor-icons/${vendor.icon}`}
                  alt={`${vendor.label} Icon`}
                />
                {vendor.label}
              </a>
            ))}
          </div>
        </div>

        <div className={styles.player}>
          {episode != null ? (
            <div>
              {episode.audioPath ? (
                <PodcastPlayer
                  episode={{
                    title: episode.title,
                    audioPath: episode.audioPath,
                    timestamps: compact(episode.timestamps),
                  }}
                />
              ) : (
                <div className={styles.audioUnavailable}>
                  Embedded audio coming soon
                </div>
              )}
            </div>
          ) : (
            <div>
              <PodcastPlayer
                episode={{
                  title: 'Trailer',
                  audioPath: trailerAudioPath,
                  timestamps: null,
                }}
              />
            </div>
          )}
        </div>
        <div>{children}</div>
      </section>
    </>
  )
}

export default PodcastLayout
