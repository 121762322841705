import React, { useContext, useState } from 'react'

type Action = 'play' | 'pause'

interface PlayerContextData {
  playing: boolean
  setPlaying: (value: boolean) => void
  requestedAction: Action | null
  setRequestedAction: (value: Action | null) => void
  seekToSeconds: number | null
  setSeekToSeconds: ((seconds: number | null) => void) | null
}

const defaultData: PlayerContextData = {
  playing: false,
  setPlaying: () => {
    /* no-op */
  },
  requestedAction: null,
  setRequestedAction: () => {
    /* no-op */
  },
  seekToSeconds: null,
  setSeekToSeconds: null,
}

const PlayerContext = React.createContext<PlayerContextData>(defaultData)

export const PlayerContextProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [playing, setPlaying] = useState<boolean>(false)
  const [requestedAction, setRequestedAction] = useState<Action | null>(null)
  const [seekToSeconds, setSeekToSeconds] = useState<number | null>(null)

  return (
    <PlayerContext.Provider
      value={{
        playing,
        setPlaying,
        requestedAction,
        setRequestedAction,
        seekToSeconds,
        setSeekToSeconds,
      }}
    >
      {props.children}
    </PlayerContext.Provider>
  )
}

export const usePlayerState = () => useContext(PlayerContext)
