// extracted by mini-css-extract-plugin
export var colorFade = "PodcastPlayer-module--colorFade--732b8";
export var container = "PodcastPlayer-module--container--9f37b";
export var currentTime = "PodcastPlayer-module--currentTime--a0217";
export var defaultPlayer = "PodcastPlayer-module--defaultPlayer--9918c";
export var duration = "PodcastPlayer-module--duration--74f39";
export var playPauseButton = "PodcastPlayer-module--playPauseButton--725ee";
export var playerRow = "PodcastPlayer-module--playerRow--3a0a5";
export var playhead = "PodcastPlayer-module--playhead--9c06c";
export var playing = "PodcastPlayer-module--playing--f2031";
export var timeCodes = "PodcastPlayer-module--timeCodes--fb05f";
export var timestamp = "PodcastPlayer-module--timestamp--1a4a5";
export var track = "PodcastPlayer-module--track--b491a";
export var trackCursor = "PodcastPlayer-module--trackCursor--5d6ad";