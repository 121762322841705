import { padStart } from 'lodash'

export function formatDuration(duration: number) {
  const seconds = Math.floor(duration) % 60
  const minutes = Math.floor(duration / 60) % 60
  const hours = Math.floor(duration / (60 * 60))

  if (hours > 0) {
    return [
      padStart(hours.toString(), 2, '0'),
      padStart(minutes.toString(), 2, '0'),
      padStart(seconds.toString(), 2, '0'),
    ].join(':')
  }

  return [
    padStart(minutes.toString(), 2, '0'),
    padStart(seconds.toString(), 2, '0'),
  ].join(':')
}
