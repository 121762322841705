import * as styles from './PodcastPlayer.module.css'

import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { compact } from 'lodash'

import { formatDuration } from 'src/utils/formatDuration'
import { parseDuration } from 'src/utils/parseDuration'

import { usePlayerState } from './PlayerContext'

interface Props {
  episode: {
    title: string
    audioPath: string
    timestamps:
      | {
          time: string | null
          label: string | null
        }[]
      | null
  }
}

export const PodcastPlayer: React.FC<Props> = (props) => {
  const { episode } = props

  const ref = useRef<HTMLAudioElement>(null)

  const {
    playing,
    setPlaying,
    seekToSeconds,
    setSeekToSeconds,
    requestedAction,
    setRequestedAction,
  } = usePlayerState()

  useEffect(() => {
    if (seekToSeconds != null && ref.current != null) {
      const audioElement = ref.current
      audioElement.currentTime = seekToSeconds
      audioElement.play()

      setSeekToSeconds && setSeekToSeconds(null)
    }
  }, [seekToSeconds, setSeekToSeconds])

  useEffect(() => {
    const audioElement = ref.current
    if (audioElement != null && requestedAction != null) {
      if (requestedAction === 'play') {
        audioElement.play()
      } else if (requestedAction === 'pause') {
        audioElement.pause()
      }
      setRequestedAction(null)
    }
  }, [requestedAction, setRequestedAction])

  const timestamps = useMemo(() => {
    if (episode.timestamps == null) {
      return []
    }

    return compact(
      episode.timestamps.map((timestamp) => {
        if (timestamp.time == null) {
          return null
        }

        return {
          time: parseDuration(timestamp.time),
          label: timestamp.label,
        }
      }),
    )
  }, [episode.timestamps])

  const [duration, setDuration] = useState<number | null>(null)
  const [currentTime, setCurrentTime] = useState<number | null>(null)

  const togglePlaying = useCallback(() => {
    if (ref.current == null) {
      return null
    }

    if (ref.current.paused) {
      ref.current.play()
    } else {
      ref.current.pause()
    }
  }, [])

  const progress = currentTime && duration ? currentTime / duration : 0

  const setProgress = useCallback(
    (progress: number) => {
      if (ref.current == null || duration == null) {
        return null
      }

      ref.current.currentTime = progress * duration
    },
    [duration],
  )

  const [hoverPosition, setHoverPosition] = useState<number | null>(null)

  return (
    <div
      className={classNames(styles.container, {
        [styles.playing]: playing,
      })}
    >
      <div className={styles.playerRow}>
        <button
          title={playing ? 'Pause Audio' : 'Play Audio'}
          type="button"
          className={styles.playPauseButton}
          onClick={togglePlaying}
        >
          {/* TODO: The pause button renders an ugly emoji on iOS */}
          {playing ? '⏸' : '⏵'}
        </button>

        <div
          className={styles.track}
          onClick={(event) => {
            setProgress(
              (event.clientX -
                (event.currentTarget.getBoundingClientRect().left +
                  document.body.scrollLeft)) /
                event.currentTarget.getBoundingClientRect().width,
            )
          }}
          onMouseMove={(event) => {
            setHoverPosition(
              (event.clientX -
                (event.currentTarget.getBoundingClientRect().left +
                  document.body.scrollLeft)) /
                event.currentTarget.getBoundingClientRect().width,
            )
          }}
          onMouseLeave={() => setHoverPosition(null)}
        >
          <div className={styles.timeCodes}>
            <div className={styles.currentTime}>
              {currentTime != null ? formatDuration(currentTime) : '--:--'}
            </div>

            {duration != null && (
              <div className={styles.duration}>{formatDuration(duration)}</div>
            )}
          </div>
          {duration != null && (
            <>
              {timestamps.map((timestamp) => (
                <div
                  className={styles.timestamp}
                  key={timestamp.time}
                  style={{ left: `${(timestamp.time / duration) * 100}%` }}
                />
              ))}
              {hoverPosition != null && (
                <div
                  className={styles.trackCursor}
                  style={{ left: `${hoverPosition * 100}%` }}
                />
              )}
              <div
                className={styles.playhead}
                style={{ right: `${100 - progress * 100}%` }}
              />
            </>
          )}
        </div>
      </div>

      <audio
        className={styles.defaultPlayer}
        controls
        src={episode?.audioPath}
        ref={ref}
        onPause={(event) => {
          setPlaying(!event.currentTarget.paused)
        }}
        onPlaying={(event) => {
          setPlaying(!event.currentTarget.paused)
        }}
        onDurationChange={(event) => {
          setDuration(event.currentTarget.duration)
        }}
        onTimeUpdate={(event) => {
          setCurrentTime(event.currentTarget.currentTime)
        }}
      >
        <a href={episode?.audioPath}>Download Audio</a>
      </audio>
    </div>
  )
}
