export const podcastVendors = [
  {
    label: 'RSS Feed',
    link: 'https://anchor.fm/s/29f49e0c/podcast/rss',
    icon: 'rss.png',
  },
  {
    label: 'Spotify',
    link: 'https://open.spotify.com/show/36zEt6boYG9LXILq4xRw7M',
    icon: 'spotify.png',
  },
  {
    label: 'Apple Podcasts',
    link: 'https://podcasts.apple.com/us/podcast/lucky-paper-radio/id1522243165',
    icon: 'apple-podcasts.png',
  },
  {
    label: 'YouTube',
    link: 'https://www.youtube.com/playlist?list=PLNL3vXDQDllcpsCbqOCqoYbCE9C6Ti4Zl',
    icon: 'youtube.png',
  },
  {
    label: 'Pocket Casts',
    link: 'https://pca.st/skz7sc31',
    icon: 'pocket-casts.png',
  },
  {
    label: 'Podcast Addict',
    link: 'https://podcastaddict.com/podcast/lucky-paper-radio/3036523#',
    icon: 'podcast-addict.png',
  },
  {
    label: 'Overcast',
    link: 'https://overcast.fm/itunes1522243165/lucky-paper-radio',
    icon: 'overcast.svg',
  },
  {
    label: 'Cube Cobra',
    link: 'https://cubecobra.com/content/podcast/5f5bacfef0a560104412d522',
    icon: 'cube-cobra.png',
  },
]
